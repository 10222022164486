html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
